import { PageProps } from 'gatsby';
import * as React from 'react';
import Layout from '../components/Layout';

const Error404Page: React.FC<PageProps> = () => (
  <Layout>
    <span className="text-xl">Deze pagina bestaat niet</span>
  </Layout>
);

export default Error404Page;
